// webpack inserts code here
window.buildData = "ver3-138-0-2024-10-04_14-32-57.77c416de";

const config = (window as any).env;
export const SERVER_URL: string = config.SERVER_URL;
export const GAME_HISTORY_URL: string = config.GAME_HISTORY_URL;
export const IS_PROD: boolean = config.IS_PROD;
export const CONSTANTS: any = {
    message_duration: 5, // in seconds
    game_history_languages: [
        'da',
        'de',
        'el',
        'en',
        'es',
        'it',
        'ja',
        'ko',
        'nl',
        'pl',
        'pt',
        'ro',
        'sv',
        'zh',
    ],
    dateFormat: 'DD/MM/YYYY',
    dateFormatWithTime: 'HH:mm:ss - DD/MM/YYYY',
};
